<template>
  <div>
    <section class="jobs">
      <div class="container">
        <div class="jobs-content">
          <div class="form-group text-right">
            <b-link class="btn-minimal" @click="openModal">{{
              $t("jobsPage.apply")
            }}</b-link>
          </div>
          <h1 class="job-title">{{ job.title }}</h1>
          <p class="job-description">{{ job.description }}</p>
          <p class="job-details" v-html="job.details"></p>
          <div class="job-location">{{ job.location }}</div>
          <div class="job-salary">{{ job.salary }}</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.job.title,
      titleTemplate: "TREBEL MUSIC: %s"
    };
  },
  computed: {
    locale() {
      return this.$store.getters.locale;
    }
  },
  data() {
    return {
      jobs: {},
      job: {}
    };
  },
  mounted() {
    let jobs = this.$t("jobs");
    this.job = jobs[this.$route.params.id];
  },
  methods: {
    openModal() {
      this.$swal({
        customClass: {
          cancelButton: "btn-submit",
          container: "swal2-custom swal2-custom-info"
        },
        buttonsStyling: false,
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: false,
        title: this.$t("jobsPage.apply"),
        html: this.$t("jobsPage.pleaseEmailYourResume"),
        cancelButtonText: "Close"
      });
    }
  }
};
</script>
